<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          ref="filterLayoutRef"
          @doCollapseEffect="getChartContainerStyle"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceholder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                        :tag-count="item.inputDataType==='taskStatus'?2:1"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState.dateValueList"
                        format="YYYY-MM"
                        valueFormat="YYYY-MM"
                        :mode="mode"
                        :open="isOpen"
                        @openChange="openChange"
                        @panelChange="handlePanelChange"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="getPlaceholder(item.inputDataType)"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                          :tag-count="item.inputDataType==='taskStatus'?2:1"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState.dateValueList"
                          format="YYYY-MM"
                          valueFormat="YYYY-MM"
                          :mode="mode"
                          :open="isOpen"
                          @openChange="openChange"
                          @panelChange="handlePanelChange"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button class="left" type="primary" ghost @click="exportClick" v-auth="['system:workTask:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, onMounted, onActivated, nextTick, watch} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {cloneDeep, debounce} from "lodash";
import { getValueAndOps } from "@/utils/orgSelectData";
import { ExportOutlined } from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {dictionaryOpt} from '@/utils/common'
export default defineComponent({
  name: "TopFilter",
  components: { ExportOutlined,MoreFormList,FormModalBox,Tooltip },
  emits: ["search", "export-click", "refresh-table","refresh-table-height"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const mode = ref(['month', 'month']);

    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams()
      ctx.emit("search", currentState);
      getOrgList();
    };
    const orgFiveOneOps = ref([])
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const workTaskTypeOps = ref([]);
    const workTaskStatusOps = ref([])
    let initData = {
      beginDate: undefined,
      endDate: undefined,
      taskType: [], // task type
      orgFiveOneList: [],
      orgThreeTwoList: [], // team
      orgFourOneOneList: [], // offering
      condition: '', // 搜索ID+name
      taskStatus: [], // task 状态
      dateValueList: [],
      taskChannel:undefined
    }
    const formState = reactive({...initData});
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: []
    });
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList }  = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      })
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const {orgsValue, orgOpsList} = getValueAndOps(orgAllList.value,formOrgData,orgOpsObj,type)
      Object.assign(formState,orgsValue)
      Object.assign(orgOpsObj,orgOpsList)
    };
    const handlePanelChange = (val) => {
      if (formState.dateValueList[1] && formState.dateValueList[1]._d !== val[1]._d) {
        isOpen.value = false;
      }
      formState.beginDate = val[0]
      formState.endDate = val[1]
      formState.dateValueList = [val[0].format('YYYY-MM'),val[1].format('YYYY-MM')]
    };
    const isOpen = ref(false);
    const openChange = (panelStatus) => {
      if (panelStatus) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
      }
    };
    const disabledBeginDate = (current) => {
      return (
        current &&
        formState.endDate &&
        current >= formState.endDate.endOf("day")
      );
    };
    const disabledEndDate = (current) => {
      return (
        current &&
        formState.beginDate &&
        current <= formState.beginDate.startOf("day")
      );
    };
    let currentState = {};
    const updateParams =() => {
      currentState = cloneDeep(formState)
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };

    const getworkTaskTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "taskType",
          },
        })
        .then((res) => {
          workTaskTypeOps.value = (res.data?.taskType || []).map(({ dictCode, dictValue}) => ({
            label: dictValue,
            value: dictCode,
          }));
        });
      $api
          .getDictionaryList({
            params: {
              dictType: "taskStatus",
            },
          })
          .then((res) => {
            workTaskStatusOps.value = (res.data?.taskStatus || []).map(({ dictCode, dictValue}) => ({
              label: dictValue,
              value: dictCode,
            }));
          });
    };
    const getChartContainerStyle = () => {
      ctx.emit("refresh-table-height");
    };
    const show = ref(true);

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'basicDataManagement',
        secondMenu: 'task',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceholder = (data) => {
      switch (data) {
        case 'condition':
          return 'Name/GGID'
        default :
          return '请输入'  // 如果没
      }
    }
    const taskChannelOps = [
      {
        label:'Hand in Hand',
        value:'Hand in Hand',
        key:'Hand in Hand',
      },
      {
        label:'RMS',
        value:'RMS',
        key:'RMS',
      },
    ]
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'taskType':
          return workTaskTypeOps.value
        case 'taskStatus':
          return workTaskStatusOps.value
        case 'taskChannel':
          return taskChannelOps
        default :
          ''  // 如果没
      }
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }

    watch(
        ()=>formState.dateValueList,
        (val)=>{
          if(val.length>0){
            formState.beginDate = val[0]
            formState.endDate = val[1]
          }else{
            formState.beginDate = ''
            formState.endDate = ''
          }
        }
    )
    onMounted(() => {
      nextTick(doSearch);
      getworkTaskTypes();
      getOrgList();
      getOrgRelatedList();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      mode,
      handlePanelChange,
      formState,
      formRef,
      disabledBeginDate,
      disabledEndDate,
      doSearch,
      resetForm,
      exportClick,
      orgOpsObj,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      workTaskTypeOps,
      workTaskStatusOps,
      orgThreeTwoChange,
      show,
      isOpen,
      openChange,
      updateParams,
      getOrgRelatedList,
      getChartContainerStyle,
      queryFormList,
      queryList,
      checkList,
      getPlaceholder,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>

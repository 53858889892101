<template>
  <section class="app-container">
    <Filter
        @search="handleSearch"
        @export-click="exportClick"
        @refresh-table="handleResetTable"
        @refresh-table-height="handleResetTableHeight"
    />
    <workTaskList ref="workTaskListRef"></workTaskList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import workTaskList from "./components/WorkTaskList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportworkTask",
  components: {
    workTaskList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const workTaskListRef = ref();
    const handleSearch = (searchParams) => {
      workTaskListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      workTaskListRef.value.doSearch();
    };
    const handleResetTableHeight = () => {
      workTaskListRef.value.getSize();
    };

    const exportClick = (formState) => {
      const beginDate = formState.beginDate
          ? formState.beginDate
          : "";
      const endDate = formState.endDate
          ? formState.endDate
          : "";
      const params = {
        ...formState,
        beginDate,
        endDate,
      };
      $api.workTaskExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      workTaskListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      handleResetTableHeight,
    };
  },
});
</script>
